import { ONE_MINUTE_IN_MS, SEC_TO_MINUTES } from 'features/cases-management/ui/cases-table/CasesTable'
import { useLisLockSignedMedicalReportAfterSec } from 'features/workspace/model/workspacesSlice'
import React, { FC, useEffect, useState } from 'react'
import { useLisModeAsDZM, useLisModeAsGemotest } from 'shared/lib/workspaces'
import { ModalHandle } from 'shared/ui/modal'
import styled from 'styled-components/macro'
import { ICaseDTO } from 'types/ICase'
import IReport from 'types/IReport'

import ReportTabLeftContent from './tabs/ReportTabLeftContent'
import ReportTabRightContent from './tabs/ReportTabRightContent'

/** Ширина правой части таба */
const LEFT_CONTENT_WIDTH = 272

type TProps = {
  /** Данные о заключение */
  report?: IReport
  /** Данные о кейсе */
  caseRecord?: ICaseDTO
  /** ref от модалки создания заключения */
  reportCreationModalRef: React.MutableRefObject<ModalHandle | null>
  /** ID кейса */
  caseId: number
}

const StyledVerticalDivider = styled.div<{ left: number }>`
  position: absolute;
  border: 1px solid var(--color-border-1);
  left: ${({ left }) => `${left}px`};
  height: 100%;
`

const ReportTabContent: FC<TProps> = ({ caseId, caseRecord, report, reportCreationModalRef }) => {
  const { locked, signed, signedAt } = report || {}
  const isGemotest = useLisModeAsGemotest()
  const isDZM = useLisModeAsDZM()
  // период в секундах, в рамках которого можно отменить подписание
  const signCancelPeriod = useLisLockSignedMedicalReportAfterSec() || 0
  // текущее время
  const currentDate = new Date()
  // количество прошедших минут после подписания
  const passedMinutes = signedAt
    ? Math.ceil((currentDate.getTime() - new Date(signedAt || '').getTime()) / ONE_MINUTE_IN_MS)
    : 0
  const leftMinutes = Number(signCancelPeriod) / SEC_TO_MINUTES - passedMinutes
  const [signCancelMinutes, setSignCancelMinutes] = useState<number>(leftMinutes > 0 ? leftMinutes : 0)
  // запускаем таймер для обновления оставшегося времени отмены подписания в ЛИС
  useEffect(() => {
    const timerId = !locked
      ? setInterval(() => {
          signCancelMinutes && setSignCancelMinutes(signCancelMinutes - 1)
        }, ONE_MINUTE_IN_MS)
      : null

    return () => {
      if (timerId) {
        clearTimeout(timerId)
      }
    }
  }, [locked, signCancelMinutes])

  // обновляем время отмены подписания, если поменлся его признак
  useEffect(() => {
    if (signed && signedAt && leftMinutes > 0 && (isGemotest || isDZM)) {
      setSignCancelMinutes(leftMinutes)
    }
  }, [signed, signedAt, leftMinutes, isGemotest, isDZM])

  return (
    <div style={{ display: 'flex', height: '100%' }}>
      <ReportTabLeftContent
        caseId={caseId}
        report={report}
        caseRecord={caseRecord}
        reportCreationModalRef={reportCreationModalRef}
      />
      <StyledVerticalDivider left={LEFT_CONTENT_WIDTH} />
      <ReportTabRightContent caseRecord={caseRecord} report={report} />
    </div>
  )
}

export default ReportTabContent
