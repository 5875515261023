import Select from 'antd/es/select'
import React from 'react'
import { TextElement } from 'shared/ui/kit'
import Flex from 'shared/ui/kit/ui/Flex'
import styled from 'styled-components'
import { DictionaryItem } from 'types/IDictionary'

const { OptGroup, Option } = Select

const SiteLabel = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 16px;
  width: 100%;
  white-space: normal;
  text-overflow: initial;
  padding-top: 2px;
`

export const createOptionGroup = (
  key: string,
  dictionaryItems: DictionaryItem[],
  searchValue: string,
  filterCb?: (_: DictionaryItem) => boolean,
) => {
  const filteredDict = filterCb ? dictionaryItems.filter(filterCb) : dictionaryItems
  const dictFilteredBySearch = filteredDict.filter((dict) => !!createOption(dict, searchValue))

  if (!dictFilteredBySearch?.length) return null

  return (
    <OptGroup label={createOptionGroupLabel(key)} key={key}>
      {dictFilteredBySearch.map((dict) => createOption(dict, searchValue))}
    </OptGroup>
  )
}

export const createOptionGroupLabel = (siteGroupeName: string) => {
  const [code, text] = siteGroupeName.split(':')
  return (
    <SiteLabel>
      <TextElement ellipsis type="secondary">
        {text}
      </TextElement>
      <TextElement type="secondary">{code}</TextElement>
    </SiteLabel>
  )
}

export const findQueryInDict = (query: string, dict: DictionaryItem) =>
  dict.name.toLowerCase().includes(query.toString().toLowerCase()) ||
  dict.shortName.toLowerCase().includes(query.toString().toLowerCase())

export const createOption = (dictItem: DictionaryItem, searchValue?: string) => {
  if (searchValue && !findQueryInDict(searchValue, dictItem)) {
    return null
  }

  const { name, shortName } = dictItem

  return (
    <Option value={shortName} key={shortName}>
      <Flex align="center" gap={4}>
        <TextElement type="secondary" style={{ whiteSpace: 'nowrap' }}>
          {shortName}
        </TextElement>
        <TextElement ellipsis>{name}</TextElement>
      </Flex>
    </Option>
  )
}
