import { Form, FormListFieldData } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import { RadioChangeEvent } from 'antd/es/radio/interface'
import { useThemeContext } from 'app/styled/ThemeProvider'
import { Icd10SelectContainer } from 'entities/icd-10'
import TopographySelectContainer from 'entities/topography/ui/TopographySelectContainer'
import { MorphologySelectContainer } from 'entities/tumor-type'
import { TNMGroupContainer } from 'features/reports/index'
import { PATHOLOGY_STATE_OPTIONS } from 'features/reports/lib/constants'
import { shouldShowOncologyFields } from 'features/reports/lib/utils'
import { useReportCreationContext } from 'features/reports/ui/report-creation/ReportCreationContext'
import { MedicalReportFormItem } from 'features/reports/ui/styled'
import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonElement, SegmentElement } from 'shared/ui/kit'
import Flex from 'shared/ui/kit/ui/Flex'
import { DictionaryValidator } from 'types/IValidators'

import Icd10BlockDeleteButton from './ICD10BlockDeleteButton'

interface Props {
  /** Функция-валидатор для проверки пустых значений в форме или данных **/
  checkSelectEmpty: DictionaryValidator
  onSegmentedClick: (name: string) => (e: React.BaseSyntheticEvent) => void
  onSegmentedChange: (name: string) => (event: RadioChangeEvent) => void
  /** Флаг, указывающий, что для текущего кейса установлен набор полей TNM */
  isTNMFieldSet: boolean
}

const Icd10BlockList: FC<Props> = ({ checkSelectEmpty, isTNMFieldSet, onSegmentedChange, onSegmentedClick }) => {
  const { t } = useTranslation()
  const theme = useThemeContext()
  const { form } = useReportCreationContext()
  const icd10List = useWatch('icd10DetailedList', form)

  const isOncologyFieldsVisibleByIndex = (index: number) => {
    const icd10Item = icd10List?.[index]?.icd10
    if (!icd10List?.length || !icd10Item || !icd10Item.shortName) {
      return false
    }
    return shouldShowOncologyFields(icd10Item.shortName)
  }

  /**
   * Эффект для установки значения по умолчанию для поля стадии онкопатологии
   *
   * Если fieldSet = TNM и для элемента списка МКБ-10 должны отображаться
   * онкологические поля, то устанавливаем значение false для поля neoadjuvantTherapy,
   * если оно еще не было установлено.
   */
  useEffect(() => {
    if (!isTNMFieldSet) return

    icd10List?.forEach((_, index) => {
      if (isOncologyFieldsVisibleByIndex(index)) {
        const currentFieldKey = ['icd10DetailedList', index, 'neoadjuvantTherapy']
        if (!form.getFieldValue(currentFieldKey)) {
          form.setFieldValue(currentFieldKey, false)
        }
      }
    })
  }, [icd10List, form, isTNMFieldSet])

  const onOncologyChange = (e: RadioChangeEvent, field: FormListFieldData) => {
    const isPostTherapy = e.target.value
    const currentPrefix = isPostTherapy ? '' : 'y'

    const tnmFields = [`${currentPrefix}pN`, `${currentPrefix}pT`, `${currentPrefix}pM`]
    tnmFields.forEach((fieldName) => {
      form.setFields([
        {
          name: ['icd10DetailedList', field.name, fieldName],
          value: undefined,
        },
      ])
    })

    onSegmentedChange('neoadjuvantTherapy')(e)
  }

  return (
    <Form.List name="icd10DetailedList">
      {(fields, { add, remove }) => (
        <Flex vertical gap={8} width="100%">
          {fields.map((field, index) => {
            const isOncologyFieldsVisible = isTNMFieldSet && isOncologyFieldsVisibleByIndex(index)

            return (
              <Flex
                key={field.key}
                vertical
                gap={8}
                style={{
                  border: '1px solid var(--color-border-1)',
                  borderRadius: 10,
                  padding: 8,
                  position: 'relative',
                }}
                width="100%"
              >
                <MedicalReportFormItem
                  label={t('МКБ-10')}
                  name={[field.name, 'icd10']}
                  rules={[{ required: true, validator: checkSelectEmpty }]}
                >
                  <Icd10SelectContainer multiline={false} />
                </MedicalReportFormItem>

                <MedicalReportFormItem
                  label={t('Топографический код (МКБ-О)')}
                  name={[field.name, 'topographicalIcdO']}
                  rules={[{ required: true, validator: checkSelectEmpty }]}
                >
                  <TopographySelectContainer />
                </MedicalReportFormItem>

                <MedicalReportFormItem
                  label={t('Морфологический код (МКБ-О)')}
                  name={[field.name, 'morphologicalIcdO']}
                  rules={[{ required: true, validator: checkSelectEmpty }]}
                >
                  <MorphologySelectContainer />
                </MedicalReportFormItem>

                {isOncologyFieldsVisible && (
                  <>
                    <MedicalReportFormItem label={t('Стадия онкопатологии')} name={[field.name, 'neoadjuvantTherapy']}>
                      <SegmentElement
                        onClick={onSegmentedClick('neoadjuvantTherapy')}
                        options={PATHOLOGY_STATE_OPTIONS}
                        style={{ maxWidth: 184 }}
                        colorTheme={theme.theme}
                        onChange={(e) => onOncologyChange(e, field)}
                        size="small"
                      />
                    </MedicalReportFormItem>

                    <TNMGroupContainer field={field} blockIndex={index} />
                  </>
                )}

                {fields.length > 1 && <Icd10BlockDeleteButton onClick={() => remove(field.name)} />}
              </Flex>
            )
          })}
          <ButtonElement
            type="dashed"
            style={{ color: 'var(--color-text-1)', width: 'fit-content' }}
            onClick={() => add()}
          >
            {t('Добавить МКБ-10')}
          </ButtonElement>
        </Flex>
      )}
    </Form.List>
  )
}

export default Icd10BlockList
