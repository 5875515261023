import { Space } from 'antd'
import { useTypedSelector } from 'app/redux/lib/selector'
import { checkIntegration } from 'entities/lis-integration'
import { getFieldLabelBylisMode } from 'entities/lis-integration/lib/checkIntegration'
import { useCaseBioMaterialsQuery } from 'features/cases/api/query'
import { EDictionaryNS } from 'features/dictionaries/api/service'
import { initDictionaries } from 'features/dictionaries/lib/handlers'
import { MarkerResultsDetailed } from 'features/new-preview-panel/content-tabs/tabs/ReportTabLeftContent'
import { useReportsDataQuery } from 'features/reports/api/query'
import { canCreateReport } from 'features/reports/hooks/common'
import { getReportModalAccess } from 'features/reports/lib/utils'
import { reportsSlice } from 'features/reports/model/reportsSlice'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import { useLisMode } from 'features/workspace/model/workspacesSlice'
import { viewerPageSlice } from 'pages/viewer'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import { QUERY_TYPE } from 'shared/api'
import { getLocalizedItem } from 'shared/lib/common'
import { formatDateForDisplay } from 'shared/lib/date'
import {
  isDefaultCase,
  isIHCCase,
  useLisModeAsDZM,
  useLisModeAsGemotest,
  useSettingsAndUserRoles,
} from 'shared/lib/workspaces'
import { Descriptions, DescriptionsItem } from 'shared/ui/description'
import { ButtonElement, TextElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import ICase from 'types/ICase'
import { DictionaryItem, ICD10Detailed } from 'types/IDictionary'
import IReport from 'types/IReport'
import ISource from 'types/ISource'

type DescriptionProps = {
  titles: string[]
  subTitle: string
}

const WideButton = styled(ButtonElement)<{ isDisabled: boolean }>`
  opacity: ${({ isDisabled }) => (isDisabled ? 0.4 : undefined)};
  width: 100%;
  margin-top: 8px;
`

const StyledDescription = styled.div`
  display: flex;
  flex-direction: column;
`

const Description = ({ subTitle, titles }: DescriptionProps) => (
  <StyledDescription>
    <TextElement style={{ color: 'var(--color-text-3)' }}>{subTitle}</TextElement>
    {titles.map((t) => (
      <TextElement key={t}>{t}</TextElement>
    ))}
  </StyledDescription>
)

const Icd10DetailedDescriptions = styled(Descriptions)`
  padding: 5px;
  background: var(--color-bg-3) !important;
  border: 1px solid var(--color-border-1);
  border-radius: 5px;
  .ant-descriptions-item-label {
    display: block;
  }
`

export const Icd10Detailed = ({
  Pn,
  g,
  icd10,
  l,
  morphologicalIcdO,
  pM,
  pN,
  pT,
  processNature,
  r,
  showTnn,
  tnmClassification,
  topographicalIcdO,
  v,
  ypM,
  ypN,
  ypT,
}: ICD10Detailed & { showTnn?: boolean }) => {
  const { t } = useTranslation()
  const isDZM = useLisModeAsDZM()

  /** Получаем shortName для отображения в "Классификация TNM" для DZM */
  const extraFields = [pT, pN, pM, ypT, ypN, ypM, g, r, l, v, Pn]
    .reduce<string[]>((acc, field) => {
      if (field?.shortName) {
        acc.push(field.shortName)
      }
      return acc
    }, [])
    .join(' ')

  return (
    <Icd10DetailedDescriptions>
      {icd10 && (
        <DescriptionsItem style={{ paddingBottom: 8 }} label={t('Код по МКБ-10')} span={3}>
          {icd10?.shortName} - {icd10.name}
        </DescriptionsItem>
      )}
      {topographicalIcdO && (
        <DescriptionsItem style={{ paddingBottom: 8 }} label={t('Топографический код по МКБ-О')} span={3}>
          {topographicalIcdO?.shortName} - {topographicalIcdO?.name}
        </DescriptionsItem>
      )}
      {morphologicalIcdO && (
        <DescriptionsItem style={{ paddingBottom: 8 }} label={t('Морфологический код МКБ-О')} span={3}>
          {morphologicalIcdO?.shortName} - {morphologicalIcdO?.name}
        </DescriptionsItem>
      )}
      {processNature && (
        <DescriptionsItem style={{ paddingBottom: 8 }} label={t('Характер процесса')} span={3}>
          {processNature?.name}
        </DescriptionsItem>
      )}
      {tnmClassification && showTnn && (
        <DescriptionsItem style={{ paddingBottom: 8 }} label={t('Классификация TNM')} span={3}>
          {tnmClassification}
        </DescriptionsItem>
      )}
      {isDZM && extraFields && (
        <DescriptionsItem style={{ paddingBottom: 8 }} label={t('Классификация TNM')} span={3}>
          {extraFields}
        </DescriptionsItem>
      )}
    </Icd10DetailedDescriptions>
  )
}

type Props = {
  openModal: () => void
  reportData?: IReport
  readOnly: boolean
  patient: ICase['patient']
  caseId: number
  source: ISource
  skipCaseInfo?: boolean
}

const ReportInfo = ({ caseId, openModal, readOnly, reportData, skipCaseInfo, source }: Props) => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const lisMode = useLisMode()
  const { t } = useTranslation()
  const isGemotest = useLisModeAsGemotest()
  const isDZM = useLisModeAsDZM()
  const wsId = Number(useCurrentWorkspaceId())
  const { data: caseReports, isLoading, refetch: refetchCaseReports } = useReportsDataQuery(caseId, source)
  const {
    roles: { isRoleDoctor },
  } = useSettingsAndUserRoles()
  const currentWorkspace = useTypedSelector((state) => state.workspaces.currentWorkspace)
  const isReportModalAvailable = getReportModalAccess(currentWorkspace)
  const caseRecord = queryClient.getQueryData<ICase>([QUERY_TYPE.CASE, caseId])
  const isIHC = isIHCCase(caseRecord)
  const isDefault = isDefaultCase(caseRecord)
  const { casePathologicalInfo, workspace } = caseRecord || {}
  const { data: caseBiomaterial } = useCaseBioMaterialsQuery({
    caseId: caseId,
    workspaceId: wsId,
  })

  const isReportCreationDisable = isDZM && !caseBiomaterial?.length

  const workspaceId = workspace?.workspaceId

  /** Доступность создания заключения */
  const reportCreationEnabled = canCreateReport({
    caseReportsLength: caseReports?.length,
    isDZM,
    isGemotest,
    isReadOnly: readOnly,
    isReportModalAvailable,
    isRoleDoctor,
    lisMode,
    signed: reportData?.signed,
  })

  const onOpenModal = async () => {
    if (isGemotest || isDZM) {
      refetchCaseReports().then((res) => {
        const report = res?.data?.at(-1)
        !report ? showNewReportModal() : queryClient.invalidateQueries([QUERY_TYPE.REPORT, { caseId }])
      })
    } else {
      showNewReportModal()
    }
  }

  const showNewReportModal = async () => {
    dispatch(reportsSlice.actions.toggleDictionaryLoading(true))
    dispatch(viewerPageSlice.actions.setIsAnyInputFocusing(true))
    dispatch(
      reportsSlice.actions.setInitialReport({
        caseMacroDescription: casePathologicalInfo?.macroDescription,
      }),
    )
    openModal()
    await initDictionaries(workspaceId)
    dispatch(reportsSlice.actions.toggleDictionaryLoading(false))
  }
  if (!reportData) {
    return reportCreationEnabled ? (
      <WideButton isDisabled={isReportCreationDisable} disabled={isReportCreationDisable} onClick={onOpenModal}>
        {t('Добавить новое заключение')}
      </WideButton>
    ) : null
  }

  const {
    comment,
    consultedBy,
    createdAt,
    createdBy,
    customInfo,
    gradeGroup,
    icd10,
    icd10DetailedList,
    markerResults,
    microDescription,
    morphologyCode,
    report,
    structuredMicroDescription,
  } = reportData

  const icd10Localized = icd10 && getLocalizedItem(icd10 as DictionaryItem, EDictionaryNS.ICD_10)
  const morphologyCodeLocalized =
    morphologyCode && getLocalizedItem(morphologyCode as DictionaryItem, EDictionaryNS.MORPHOLOGY_CODE)

  const morphDescription = []
  morphologyCodeLocalized && morphDescription.push(morphologyCodeLocalized.name)
  gradeGroup && morphDescription.push(`\nGrade: ${gradeGroup}`)

  return (
    <div>
      <Space size={8} direction="vertical" style={{ width: '100%' }}>
        {/* Дата заключения */}
        {checkIntegration('report', 'createdBy-createdAt', lisMode) && createdBy && (
          <Description titles={[formatDateForDisplay(createdAt) || '-']} subTitle={t('Дата заключения')} />
        )}
        {lisMode !== 'DeGenie' && (
          <>
            {/* МКБ-10 */}
            {checkIntegration('report', 'icd10', lisMode) && icd10Localized && (
              <Description titles={[`${icd10Localized.shortName} ${[icd10Localized.name]}`]} subTitle={t('МКБ-10')} />
            )}
            {/* Категория сложности */}
            {checkIntegration('report', 'complexity', lisMode) && customInfo?.complexity && (
              <Description titles={[String(customInfo?.complexity)]} subTitle={t('Категория сложности')} />
            )}
            {/* Морфологический код */}
            {checkIntegration('report', 'morphologyCode', lisMode) && morphologyCodeLocalized && (
              <Description
                titles={[`${morphologyCodeLocalized.shortName} ${morphDescription}`]}
                subTitle={t('Морфологический код')}
              />
            )}
            {/* Патологоанатомическое заключение */}
            {checkIntegration('report', 'report', lisMode, isDZM ? isDefault : true) && report && (
              <Description titles={[report]} subTitle={t(isDZM ? 'Патологоанатомическое заключение' : 'Заключение')} />
            )}
            {/* Структурированное Микроописание для ДЗМ*/}
            {checkIntegration('report', 'structuredMicroDescription', lisMode) &&
              structuredMicroDescription?.some((item) => item.text) && (
                <Description
                  titles={[structuredMicroDescription?.map((item) => item.text).join(' ')]}
                  subTitle={t(isIHC ? 'Микроописание (ИГХ)' : 'Микроописание')}
                />
              )}
            {/* Результаты доп. исследований */}
            {checkIntegration('report', 'additionalResearchResult', lisMode) &&
              isIHC &&
              customInfo?.additionalResearchResult && (
                <Description
                  titles={[customInfo?.additionalResearchResult]}
                  subTitle={t('Результаты доп. исследований')}
                />
              )}
            {/* Микроописание для гемотеста*/}
            {checkIntegration('report', 'microDescription', lisMode) && microDescription && (
              <Description titles={[microDescription]} subTitle={t('Микроописание')} />
            )}
            {/* Доп. замечания и рекомендации */}
            {checkIntegration('report', 'comment', lisMode, isDZM ? isDefault : true) && comment && (
              <Description titles={[comment]} subTitle={getFieldLabelBylisMode('comment', lisMode)} />
            )}
            {/* Результат ИГХ исследования */}
            {checkIntegration('report', 'ihcResult', lisMode, isIHC) && customInfo?.ihcResult && (
              <Description titles={[customInfo?.ihcResult]} subTitle={getFieldLabelBylisMode('ihcResult', lisMode)} />
            )}
          </>
        )}
        {/* Результаты по маркерам */}
        {markerResults?.length && isDZM && <MarkerResultsDetailed markerResults={markerResults} />}
        {/* МКБ-10 */}
        {checkIntegration('report', 'icd10DetailedList', lisMode) &&
          icd10DetailedList?.map((it, idx) => <Icd10Detailed key={idx} {...it} showTnn={isDefault} />)}
        {/* Врач-патологоанатом */}
        {checkIntegration('report', 'createdBy-createdAt', lisMode) && createdBy?.userId && (
          <Description titles={[createdBy?.fullname]} subTitle={t('Врач-патологоанатом')} />
        )}
        {/* Консультант */}
        {checkIntegration('report', 'consultedBy', lisMode) && consultedBy?.userId && (
          <Description titles={[consultedBy?.fullname]} subTitle={t('Консультант')} />
        )}
      </Space>
      {reportCreationEnabled && !skipCaseInfo && (
        <WideButton
          isDisabled={isReportCreationDisable}
          disabled={isReportCreationDisable}
          loading={isLoading}
          onClick={onOpenModal}
        >
          {t('Добавить новое заключение')}
        </WideButton>
      )}
    </div>
  )
}

export default ReportInfo
