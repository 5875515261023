import { IBillableService } from 'types/IDictionary'
import IReport from 'types/IReport'
import IUser from 'types/IUser'

/**
 * Фильтрация списка услуг по уровню сложности и типу оплаты.
 *
 * @param {Array} services - Массив объектов услуг для фильтрации.
 * @param {number} complexity - Требуемый уровень сложности услуги.
 * @param {string} billingType - Требуемый тип оплаты услуги.
 * @returns {Array} - Отфильтрованный массив услуг.
 */
export const getFilteredServices = (
  complexity: number,
  billingType?: string | null,
  services?: IBillableService[],
): IBillableService[] => {
  if (!billingType) return []
  return (
    services?.filter((service) => {
      // Проверяем, что уровень сложности совпадает
      const hasMatchingComplexity = service.complexities.includes(complexity)

      // Проверяем, что тип оплаты совпадает
      const hasMatchingBillingType = service.billingTypes.some((type) => type.name === billingType)

      return hasMatchingComplexity && hasMatchingBillingType
    }) || []
  )
}

export const canEditReport = ({
  currentUser,
  isDZM,
  isGemotest,
  isReportModalAvailable,
  isRestricted,
  isRoleDoctor,
  lisMode,
  report,
  signed,
}: {
  isDZM: boolean
  isGemotest: boolean
  isReportModalAvailable: boolean
  signed?: boolean | null
  lisMode?: string
  report?: IReport
  currentUser?: IUser
  isRoleDoctor?: boolean
  isRestricted?: boolean
}) => {
  const isReportEditEnableForDZMOrGemotest = (isDZM || isGemotest) && !signed
  const isReportEditEnableForNoneLisMode = lisMode === 'none' && report?.createdBy?.userId === currentUser?.userId

  return (
    isRoleDoctor &&
    isReportModalAvailable &&
    (isReportEditEnableForDZMOrGemotest || isReportEditEnableForNoneLisMode) &&
    !isRestricted
  )
}

export const canCreateReport = ({
  caseReportsLength,
  isDZM,
  isGemotest,
  isReadOnly,
  isReportModalAvailable,
  isRoleDoctor,
  lisMode,
  signed,
}: {
  isDZM: boolean
  isGemotest: boolean
  caseReportsLength?: number
  lisMode?: string
  signed?: boolean | null
  isReadOnly?: boolean
  isRoleDoctor?: boolean
  isReportModalAvailable: boolean
}) => {
  const isReportCreationEnableForDZMOrGemotest = (isDZM || isGemotest) && !caseReportsLength
  const isReportCreationEnableInNoneLisMode = lisMode === 'none' && !signed

  return (
    !isReadOnly &&
    isRoleDoctor &&
    isReportModalAvailable &&
    (isReportCreationEnableForDZMOrGemotest || isReportCreationEnableInNoneLisMode)
  )
}
