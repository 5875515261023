import { CaseBiomaterial } from 'features/cases/api/service'
import { StructuredDescription } from 'types/ICase'

/**
 * Хук для объединения данных caseBiomaterial и structuredDescription
 */
export const useMergedStructuredDescriptions = (
  caseBiomaterial?: CaseBiomaterial[],
  structuredDescription?: StructuredDescription[],
) => {
  if (!caseBiomaterial) return []

  // Создаем Map, где ключ — bioMaterialId, значение — biomaterialExternalId и text
  const descriptionsMap = new Map(
    structuredDescription?.map(({ bioMaterialId, biomaterialExternalId, text }) => [
      bioMaterialId,
      {
        biomaterialExternalId,
        text,
      },
    ]) ?? [],
  )

  // Формируем итоговый массив с bioMaterialId и соответствующим text (или null)
  return caseBiomaterial.map(({ bioMaterialId, externalId }) => {
    const { biomaterialExternalId, text } = descriptionsMap.get(bioMaterialId) || {}
    return {
      bioMaterialId,
      biomaterialExternalId: biomaterialExternalId ?? externalId,
      text: text ?? null,
    }
  })
}
