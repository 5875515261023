import { useTypedDispatch } from 'app/redux/lib/selector'
import { initDictionaries } from 'features/dictionaries'
import {
  useReportCreateMutation,
  useReportEditMutation,
  useReportRemoveMutation,
  useReportsDataQuery,
} from 'features/reports/api/query'
import {
  calculateLisVersion,
  CONFLICT_SERVER_CODE,
  CONFLICT_SIGNED_CODE,
  getUiErrorMessage,
} from 'features/reports/lib/common'
import { reportsSlice } from 'features/reports/model/reportsSlice'
import { ReportFormData, useReportCreationContext } from 'features/reports/ui/report-creation/ReportCreationContext'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import { viewerPageSlice } from 'pages/viewer'
import { RefObject, useCallback } from 'react'
import { useQueryClient } from 'react-query'
import { QUERY_TYPE } from 'shared/api'
import { clearEmptyProps } from 'shared/lib/common'
import { useLisModeAsDZM, useLisModeAsGemotest } from 'shared/lib/workspaces'
import { ModalHandle } from 'shared/ui/modal'
import IReport from 'types/IReport'
import ISource from 'types/ISource'

type Props = {
  /** Идентификатор случая */
  caseId: number
  /** Индекс выбранного заключения */
  selectedReportIndex: number
  /** Ссылка на компонент модального окна */
  modalRef: RefObject<ModalHandle>
  /** источник заключения */
  source: ISource
  /** Данные начального отчета для создания нового отчета */
  creationModalInitialReport?: Partial<IReport>
}

export const useReportManagement = ({
  caseId,
  creationModalInitialReport,
  modalRef,
  selectedReportIndex,
  source,
}: Props) => {
  const queryClient = useQueryClient()
  const dispatch = useTypedDispatch()
  const isGemotest = useLisModeAsGemotest()
  const isDZM = useLisModeAsDZM()
  const workspaceId = useCurrentWorkspaceId()

  const { data, refetch: refetchCaseReports } = useReportsDataQuery(caseId, source)
  const { isLoading: isReportCreating, mutate: createMutate } = useReportCreateMutation()
  const { isLoading: isReportUpdating, mutate: editMutate } = useReportEditMutation()
  const { isLoading: isRemoveLoading, mutate: removeMutate } = useReportRemoveMutation()

  const {
    errorCode,
    form,
    selectedServices,
    setBillingTypeReportError,
    setCanRevokeSignature,
    setErrorCode,
    setIsActionModalVisible,
    setIsConflictError,
    setIsDrafting,
    setIsSigning,
    setSaveReportError,
  } = useReportCreationContext()

  const resetReportError = () => {
    setIsConflictError(false)
    setSaveReportError('')
    setBillingTypeReportError(null)
  }

  const onCloseModal = useCallback(() => {
    setIsActionModalVisible(false)
    setIsDrafting(false)
    resetReportError()
    initDictionaries(String(workspaceId))
    dispatch(viewerPageSlice.actions.setIsAnyInputFocusing(false))
    modalRef.current?.close()
    dispatch(reportsSlice.actions.setInitialReport(undefined))
    form.resetFields()
  }, [workspaceId, form, modalRef, resetReportError])

  const onCancelModal = () => setIsActionModalVisible(true)

  const onSaveReportError = (error: any) => {
    const data = error?.response?.data
    const canRevokeSignature = data?.canRevokeSignature ?? true
    const errorCode = data?.code
    const isConflict = data?.status === CONFLICT_SERVER_CODE
    const updatedUser = data?.actualMedicalReport?.updatedBy?.fullname || data?.updatedBy?.fullname
    const uiError =
      getUiErrorMessage(errorCode, isConflict, updatedUser, canRevokeSignature) || String(data?.message || error)
    setCanRevokeSignature(canRevokeSignature)
    setErrorCode(errorCode)
    setIsDrafting(false)
    setIsSigning(false)
    setIsConflictError(isConflict)
    setSaveReportError(uiError)
    setIsActionModalVisible(true)
    refetchCaseReports()
  }

  const onSuccess = useCallback(() => {
    onCloseModal()
    queryClient.invalidateQueries([QUERY_TYPE.REPORT_DATA, { caseId }])
  }, [caseId, onCloseModal])

  const handleSaveMutation = useCallback(
    async (clearFormReport, payload) => {
      let reportId: number | undefined
      if (!creationModalInitialReport?.medicalReportId) {
        const results = await refetchCaseReports()
        const updatedReport = results?.data?.find((report) => report.medicalReportId)
        reportId = updatedReport?.medicalReportId
      } else {
        reportId = creationModalInitialReport?.medicalReportId
      }

      if (reportId) {
        // Если черновик уже существует - обновляем его
        editMutate(
          {
            caseId,
            report: errorCode === CONFLICT_SIGNED_CODE ? { ...clearFormReport, signed: false } : clearFormReport,
            reportId: reportId,
          },
          { onError: onSaveReportError, onSuccess },
        )
      } else {
        // Если черновика еще нет - создаем новый
        createMutate(
          {
            ...payload,
            caseId,
            report: clearFormReport,
          },
          { onError: onSaveReportError, onSuccess },
        )
      }
    },
    [caseId, creationModalInitialReport, selectedServices, createMutate, editMutate, onSaveReportError, onSuccess],
  )

  const onSave = useCallback(
    async (
      {
        additionalResearchRequired,
        additionalResearchResult,
        caseMacroDescription,
        comment,
        complexity,
        consultedBy,
        icd10,
        icd10DetailedList,
        ihcResult,
        markerResults,
        microDescription,
        morphologyCode,
        report,
        signed,
        structuredMacroDescription,
        structuredMicroDescription,
      }: ReportFormData,
      hasDraftSaved?: boolean,
    ) => {
      const payload = isGemotest || isDZM ? { signed } : {}

      const lisVersion =
        data?.find((report) => report.medicalReportId === creationModalInitialReport?.medicalReportId)?.lisVersion ||
        creationModalInitialReport?.lisVersion

      const baseLisVersion = calculateLisVersion(lisVersion, creationModalInitialReport?.lisVersion)
      const lisVersionToSave =
        hasDraftSaved && !creationModalInitialReport?.medicalReportId ? baseLisVersion + 1 : baseLisVersion

      const formReport = {
        ...creationModalInitialReport,
        ...payload,
        additionalResearchRequired,
        billableServices: selectedServices,
        caseId,
        caseMacroDescription,
        comment,
        consultedBy: isDZM ? consultedBy : undefined,
        customInfo: { additionalResearchResult, complexity: complexity ?? undefined, ihcResult, type: 'BASIC' },
        icd10,
        icd10DetailedList,
        lisVersion: lisVersionToSave,
        markerResults,
        microDescription,
        morphologyCode,
        report,
        structuredMacroDescription,
        structuredMicroDescription,
      }

      const clearFormReport = clearEmptyProps(formReport)
      handleSaveMutation(clearFormReport, payload)
    },
    [caseId, creationModalInitialReport, selectedServices, data, handleSaveMutation, isDZM, isGemotest],
  )

  const onDelete = useCallback(
    (reportId) => {
      resetReportError()
      removeMutate(
        { caseId, reportId },
        {
          onSuccess: () => {
            const reportsLength = data?.length || 0
            if (selectedReportIndex + 1 > reportsLength) {
              dispatch(reportsSlice.actions.setSelectedReportIndex({ caseId, index: selectedReportIndex - 1 }))
            }
            onSuccess()
          },
        },
      )
    },
    [caseId, data, selectedReportIndex, onSuccess, removeMutate, resetReportError],
  )

  return {
    isLoading: {
      isRemoveLoading,
      isReportCreating,
      isReportUpdating,
    },
    onCancelModal,
    onCloseModal,
    onDelete,
    onSave,
  }
}
