import { Divider } from 'antd'
import { modalBackground, modalShadow } from 'app/styled/GlobalStyles'
import { CONFLICT_SIGNED_CODE, getButtonLabel } from 'features/reports/lib/common'
import { t } from 'i18next'
import React, { FC } from 'react'
import { ButtonElement, TitleElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'

type Props = {
  /** Флаг, указывающий, есть ли lisMode. */
  isLisMode: boolean
  /** Флаг, указывающий на ошибку в валидации. */
  hasErrors: boolean
  /** Сообщение об ошибке при сохранении заключения */
  saveReportError: string
  /** Код ошибки */
  errorCode: string
  /** Флаг, указывающий, что в данный момент происходит сохранение данных. */
  isSaving: boolean
  /** Функция, вызываемая при нажатии кнопки "Вернуться в форму". */
  onCancel: () => void
  /** Функция, вызываемая при нажатии кнопки "Уйти без сохранения". */
  onDiscard: () => void
  /** Функция, вызываемая при нажатии кнопки "Сохранить" */
  onSave: () => void
  /** Флаг, указывающий, что заключение можно подписать */
  canRevoke: boolean
}

const ActionConfirmationModal: FC<Props> = ({
  canRevoke,
  errorCode,
  hasErrors,
  isLisMode,
  isSaving,
  onCancel,
  onDiscard,
  onSave,
  saveReportError,
}) => {
  const cantRevokeSignature = !canRevoke && errorCode === CONFLICT_SIGNED_CODE
  return (
    <Overlay>
      <StyledModal>
        <StyledTitle>
          {t(
            saveReportError || (hasErrors ? 'Не все обязательные поля заполнены' : 'Остались несохраненные изменения'),
          )}
        </StyledTitle>
        <StyledDivider />
        <ButtonContainer>
          {!cantRevokeSignature && (
            <StyledButton
              disabled={isSaving || (hasErrors && !isLisMode)}
              loading={isSaving}
              type="primary"
              onClick={onSave}
            >
              {getButtonLabel(errorCode, isLisMode)}
            </StyledButton>
          )}
          {!saveReportError && <StyledButton onClick={onDiscard}>{t('Уйти без сохранения')}</StyledButton>}
          <StyledButton onClick={cantRevokeSignature ? onDiscard : onCancel}>
            {cantRevokeSignature
              ? t('Закрыть')
              : saveReportError
              ? t('Вернуться к редактированию')
              : t('Вернуться в форму')}
          </StyledButton>
        </ButtonContainer>
      </StyledModal>
    </Overlay>
  )
}

export default ActionConfirmationModal

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`

const StyledDivider = styled(Divider)`
  margin: 16px 0 !important;
`

const StyledTitle = styled(TitleElement)`
  font-size: 14px !important;
  font-weight: 500 !important;
  text-align: center !important;
  line-height: 20px !important;
  width: 280px;
`

const StyledButton = styled(ButtonElement)`
  margin: 0 !important;
  font-size: 12px;
  font-weight: 500;
  border-radius: 5px;
  height: 24px;
`

const ButtonContainer = styled.div`
  display: inline-grid;
  row-gap: 8px;
  width: 100%;
  & > *:not(:last-child) {
    margin-right: 6px;
  }
`

const StyledModal = styled.div`
  ${() => modalBackground}
  ${() => modalShadow}
  padding: 16px;
  border-radius: 5px;
  text-align: center;
  z-index: 1001;
`
