import ActionConfirmationModal from 'features/reports/ui/ActionConfirmationModal'
import { ReportFormData, useReportCreationContext } from 'features/reports/ui/report-creation/ReportCreationContext'
import SearchServices from 'features/reports/ui/report-creation/services-modal/SearchServices'
import React, { FC, memo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { QUERY_TYPE } from 'shared/api'
import { useLisModeAsDZM, useSettingsAndUserRoles } from 'shared/lib/workspaces'
import Flex from 'shared/ui/kit/ui/Flex'
import styled from 'styled-components/macro'
import ICase from 'types/ICase'

import ReportCreationFormLeftBodyContainer from './ReportCreationFormLeftBodyContainer'
import ReportCreationFormLeftFooterContainer from './ReportCreationFormLeftFooterContainer'
import ReportCreationFormLeftHeader from './ReportCreationFormLeftHeader'

interface Props {
  /**
   * Колбек отмены формирования заключения
   */
  onCancel: () => void
  /**
   * Сохранение заключения
   * @param payload
   */
  onSave: (payload: ReportFormData, hasDraftSaved?: boolean) => Promise<void>
  /**
   * Функция, вызываемая для удаления заключения.
   */
  onDelete: (reportId: number) => void
  /**
   * Состояние сохранения заключения
   */
  isSaving: boolean
  /**
   * Флаг, указывающий, что заключение в данный момент удаляется.
   */
  isDeleting: boolean
  /**
   * ID кейса
   */
  caseId: number
  /**
   * Функция, вызываемая при закрытии модального окна.
   */
  onCloseModal: () => void
}

const ReportCreationFormLeftContainer: FC<Props> = memo(
  ({ caseId, isDeleting, isSaving, onCancel, onCloseModal, onDelete, onSave }) => {
    const { t } = useTranslation()
    const queryClient = useQueryClient()
    const isDZM = useLisModeAsDZM()
    const {
      canRevokeSignature,
      errorCode,
      form,
      hasValidateErrors,
      initialReport,
      isActionModalVisible,
      isDrafting,
      isServicesModalVisible,
      isSigning,
      saveReportError,
      setBillingTypeReportError,
      setIsActionModalVisible,
      setIsDrafting,
      setIsSigning,
      setSaveReportError,
      setSelectComplexity,
      setSelectedServices,
    } = useReportCreationContext()
    const { isLisMode } = useSettingsAndUserRoles()

    const caseRecord: ICase | undefined = queryClient.getQueryData([QUERY_TYPE.CASE, caseId])
    const billingTypeName = caseRecord?.billingType?.name
    /**
     * Обрабатывает событие подписания формы.
     * Устанавливает значение поля `signed` в `true`, валидирует поля формы
     * и вызывает сохранение данных.
     *
     * @async
     * @function
     * @returns {Promise<void>} Возвращает промис, который разрешается после завершения операции.
     */
    const onSign = async (): Promise<void> => {
      if (isDZM && !caseRecord?.billingType) {
        setBillingTypeReportError(t('В ЛИС не указан тип финансирования'))
        return
      }
      setIsSigning(true)
      form.setFieldValue('signed', true)

      try {
        await form.validateFields()
        await onSaveClick()
      } finally {
        setIsSigning(false)
      }
    }

    /** Формирует объект payload из значений формы. */
    const getPayload = () => {
      const consultedBy = form.getFieldValue('consultedBy')
      const payload = {
        ...form.getFieldsValue(),
        ...(consultedBy ? { consultedBy: { userId: consultedBy } } : {}),
      }
      return payload
    }

    /**
     * Сохраняет данные формы.
     * Вызывает переданный обработчик сохранения с текущими значениями полей формы.
     *
     * @async
     * @function
     * @returns {Promise<void>} Возвращает промис, который разрешается после завершения операции.
     */
    const onSaveClick = async (): Promise<void> => {
      const payload = getPayload()
      await onSave(payload)
    }

    const handleDraftSave = async (hasDraftSaved = false): Promise<void> => {
      setIsSigning(false)
      setIsDrafting(true)
      try {
        const payload = getPayload()
        await form.validateFields()
        await onSave(payload, hasDraftSaved)
      } finally {
        setIsDrafting(false)
      }
    }

    // Функция сохранения черновика для футера
    const onSaveDraft = async (): Promise<void> => {
      await handleDraftSave(false)
    }

    // Функция сохранения черновика для модального окна
    const onSaveDraftCreated = async (): Promise<void> => {
      await handleDraftSave(true)
    }

    const handleCancelConfirm = () => {
      setSaveReportError('')
      setIsActionModalVisible(false)
    }

    const caseName = caseRecord?.name ?? t('Не указано')
    const billingType = caseRecord?.billingType?.name ?? t('-')

    useEffect(() => {
      if (initialReport.billableServices?.length) setSelectedServices(initialReport.billableServices)
      return () => {
        setSelectComplexity(null)
      }
    }, [])

    return (
      <Wrapper vertical justify="space-between">
        <ReportCreationFormLeftHeader title={caseName} onCancel={onCancel} billingType={billingType} isDZM={isDZM} />
        <ReportCreationFormLeftBodyContainer isSigning={isSigning} caseId={caseId} />
        <ReportCreationFormLeftFooterContainer
          onSaveClick={onSaveClick}
          onDelete={onDelete}
          onCancel={onCancel}
          isSaving={isSaving}
          isDeleting={isDeleting}
          onSign={onSign}
          onSaveDraft={onSaveDraft}
          isSigning={isSigning}
          isDrafting={isDrafting}
        />
        {isActionModalVisible && (
          <ActionConfirmationModal
            hasErrors={hasValidateErrors}
            errorCode={errorCode}
            saveReportError={saveReportError}
            onCancel={handleCancelConfirm}
            onDiscard={onCloseModal}
            isSaving={isSaving}
            isLisMode={isLisMode}
            onSave={!isLisMode ? onSaveClick : onSaveDraftCreated}
            canRevoke={canRevokeSignature}
          />
        )}
        {isServicesModalVisible && <SearchServices billingTypeName={billingTypeName} />}
      </Wrapper>
    )
  },
)

export default ReportCreationFormLeftContainer

const Wrapper = styled(Flex)`
  width: 409px;
  min-width: 409px;
  height: 100%;
  border-right: 1px solid var(--color-border-1);
`
