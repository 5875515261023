import { checkIntegration } from 'entities/lis-integration'
import { getFieldLabelBylisMode } from 'entities/lis-integration/lib/checkIntegration'
import { useLisMode } from 'features/workspace/model/workspacesSlice'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { isDefaultCase, isIHCCase, useLisModeAsDZM } from 'shared/lib/workspaces'
import { TextElement, TitleElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import { ICaseDTO, StructuredDescription } from 'types/ICase'
import IReport from 'types/IReport'

type TProps = {
  /** Данные о заключение */
  report?: IReport
  /** Данные о кейсе */
  caseRecord?: ICaseDTO
}

export const RowDescription = ({
  description,
  subTitle,
  title,
}: {
  title?: string
  description: string
  subTitle?: string
}) => (
  <RowWrapper>
    {title && <StyledTitle>{title}</StyledTitle>}
    {subTitle && <StyledSubTitle>{subTitle}</StyledSubTitle>}
    <TextElement>{description}</TextElement>
  </RowWrapper>
)

const ReportTabRightContent: FC<TProps> = ({ caseRecord, report }) => {
  const { t } = useTranslation()
  const {
    caseMacroDescription,
    comment,
    customInfo,
    microDescription,
    report: textReport,
    structuredMacroDescription,
    structuredMicroDescription,
  } = report || {}

  const lisMode = useLisMode()
  const isDZM = useLisModeAsDZM()
  const isIHC = isIHCCase(caseRecord)
  const isDefault = isDefaultCase(caseRecord)

  /** Структурированные макро и микро-описания */
  const renderDescriptions = (title: string, descriptions: StructuredDescription[] | null | undefined) => {
    if (!descriptions) return null
    const validItems = descriptions.filter((item) => item.text)
    if (validItems.length === 0) return null
    return (
      <DescriptionContainer>
        <StyledTitle>{title}</StyledTitle>
        <ContainersWrapper>
          {validItems.map((item, index) => (
            <RowDescription
              key={index}
              subTitle={`${t('Контейнер')} ${item.biomaterialExternalId}`}
              description={item.text || ''}
            />
          ))}
        </ContainersWrapper>
      </DescriptionContainer>
    )
  }

  return (
    <div style={{ width: '100%' }}>
      <ContentWrapper>
        {/* Структурированное Макро- и микроописание для ДЗМ*/}
        {isDZM && (
          <>
            {renderDescriptions(t('Макроописание'), structuredMacroDescription)}
            {renderDescriptions(t(isIHC ? 'Микроописание (ИГХ)' : 'Микроописание'), structuredMicroDescription)}
          </>
        )}
        {/* Макро- и микроописание для гемотеста*/}
        {!isDZM && (
          <>
            {caseMacroDescription && <RowDescription title={t('Макроописание')} description={caseMacroDescription} />}
            {microDescription && <RowDescription title={t('Микроописание')} description={microDescription} />}
          </>
        )}
        {/* Результаты доп. исследований */}
        {checkIntegration('report', 'additionalResearchResult', lisMode) && customInfo?.additionalResearchResult && (
          <RowDescription description={customInfo?.additionalResearchResult} title={t('Результат доп исследований')} />
        )}
        {/* Результат ИГХ исследования */}
        {checkIntegration('report', 'ihcResult', lisMode, isIHC) && customInfo?.ihcResult && (
          <RowDescription title={getFieldLabelBylisMode('ihcResult', lisMode)} description={customInfo?.ihcResult} />
        )}
        {/* Патологоанатомическое заключение */}
        {checkIntegration('report', 'report', lisMode, isDZM ? isDefault : true) && textReport && (
          <RowDescription
            title={t(isDZM ? 'Патологоанатомическое заключение' : 'Заключение')}
            description={textReport}
          />
        )}
        {/* Доп. замечания и рекомендации */}
        {checkIntegration('report', 'comment', lisMode, isDZM ? isDefault : true) && comment && (
          <RowDescription title={getFieldLabelBylisMode('comment', lisMode)} description={comment} />
        )}
      </ContentWrapper>
    </div>
  )
}

export default ReportTabRightContent

const StyledTitle = styled(TitleElement)`
  &.ant-typography {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: var(--color-text-3);
  }
`

const StyledSubTitle = styled(TitleElement)`
  &.ant-typography {
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
  }
`

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const ContainersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const ContentWrapper = styled.div`
  display: grid;
  row-gap: 8px;
  width: 100%;
  padding: 16px;
  gap: 10px;
`

const RowWrapper = styled.div`
  display: grid;
  row-gap: 2px;
  width: 100%;
`
