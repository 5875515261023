import { EDictionaryNS } from 'features/dictionaries/api/service'
import { MedicalReportFormItem } from 'features/reports/ui/styled'
import React, { FC, PropsWithChildren } from 'react'
import useDictSelect from 'shared/lib/dict-select/useDictSelect'
import { createOption } from 'shared/lib/dict-select/utils'
import { LoadingContainer, StyledSpineElement } from 'shared/ui/dict-select/DictLoading.styled'
import { InputElement, SelectElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import { DictionaryItem } from 'types/IDictionary'
import { DictionaryValidator, StringValidator } from 'types/IValidators'

interface Props {
  /** Окраска для выбираемого справочника **/
  stainId?: number
  /** Функция-валидатор для проверки пустых значений в форме или данных **/
  checkSelectEmpty: DictionaryValidator
  /** Функция-валидатор для строк **/
  stringValidator: StringValidator
  /** Идентификатор элемента markerResult в массиве markerResults **/
  fieldNumber: number
}
const StyledMedicalReportFormItem = styled(MedicalReportFormItem)`
  flex: 1;
  max-width: 120px;
`
const StyledSelect = styled(SelectElement)`
  &.tnm-select {
    .ant-select-selector {
      overflow: hidden;
    }
  }
`
const MarkerResultSelectContainer: FC<PropsWithChildren<Props>> = ({
  checkSelectEmpty,
  fieldNumber,
  stainId,
  stringValidator,
}) => {
  const {
    dictionaryData: dictData,
    isDictLoading,
    placeholder,
  } = useDictSelect({
    dictType: EDictionaryNS.MARKER_RESULT,
    stainId,
  })

  if (isDictLoading)
    return (
      <StyledMedicalReportFormItem>
        <LoadingContainer>
          <StyledSpineElement />
        </LoadingContainer>
      </StyledMedicalReportFormItem>
    )

  const isValidDictData = !!dictData?.length
  const rules = [{ required: true, validator: isValidDictData ? checkSelectEmpty : stringValidator }]

  return isValidDictData ? (
    <StyledMedicalReportFormItem name={[fieldNumber, 'markerResult']} rules={rules}>
      <MarkerStyledSelect placeholder={placeholder} dictData={dictData} />
    </StyledMedicalReportFormItem>
  ) : (
    <StyledMedicalReportFormItem rules={rules} name={[fieldNumber, 'resultText']}>
      <InputElement />
    </StyledMedicalReportFormItem>
  )
}

export default MarkerResultSelectContainer
interface MarkerStyledSelectProps {
  /** Выбранный элемент справочника окраски **/
  value?: DictionaryItem
  /** Подходящий справочник для окраски **/
  dictData: DictionaryItem[]
  onChange?: (marker?: DictionaryItem) => void
  placeholder: string
}
const MarkerStyledSelect = ({ dictData, onChange, placeholder, value }: MarkerStyledSelectProps) => {
  const onMarkerChange = (id: string) => {
    const marker = dictData?.find(({ shortName }) => shortName === id)
    marker && onChange?.(marker)
  }
  const { name, shortName } = value || {}
  return (
    <StyledSelect placeholder={placeholder} filterOption={false} value={shortName || name} onChange={onMarkerChange}>
      {dictData?.map((dict: DictionaryItem) => createOption(dict))}
    </StyledSelect>
  )
}
