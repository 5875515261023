import { useTypedSelector } from 'app/redux/lib/selector'
import { canEditReport } from 'features/reports/hooks/common'
import { getReportModalAccess } from 'features/reports/lib/utils'
import { useLisMode } from 'features/workspace/model/workspacesSlice'
import { selectAtlasViewerUrlSlideId } from 'pages/viewer'
import { useOpenViewers } from 'pages/viewer/lib/common/ViewerPageProvider'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLisModeAsDZM, useLisModeAsGemotest } from 'shared/lib/workspaces'
import { IconElement, SmallIconButtonElement, TextElement, TitleElement, TooltipElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import IReport from 'types/IReport'
import IUserRole from 'types/IUserRole'

const Header = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
`
const Actions = styled.div`
  display: flex;
`

const RightActions = styled(Actions)`
  flex-grow: 1;
  flex: 1;
  justify-content: flex-end;
  gap: 4px;
`
const ActionBtn = styled(SmallIconButtonElement)`
  &.ant-btn-icon-only {
    width: 16px;
    height: 16px;
    padding: 0;
  }
`

type Props = {
  /** Выбранное заключение */
  selectedReport: IReport | undefined
  /** Общее количество заключений */
  reportsLength: number
  /** Текущий индекс заключения*/
  index: number
  /** Функция для перехода к следующему заключению */
  next: () => void
  /** Функция для возврата к предыдущему заключению */
  back: () => void
  /** Функция для копирования заключения */
  copyReport: () => void
  /** Функция для редактирования заключения */
  editReport: () => void
  /** Возможность копировать заключение */
  copyDisable: boolean
  /** Флаг определяющий relation */
  isRestricted: boolean
  /** Флаг, блокирующий редактирование заключения */
  isDisabledEditReport: boolean
}

const ReportsWidgetHeader = ({
  back,
  copyDisable,
  copyReport,
  editReport,
  index,
  isDisabledEditReport,
  isRestricted,
  next,
  reportsLength,
  selectedReport,
}: Props) => {
  const leftDisabled = index === 0
  const { t } = useTranslation()
  const lisMode = useLisMode()
  const isGemotest = useLisModeAsGemotest()
  const isDZM = useLisModeAsDZM()
  const isAtlas = !useSelector(selectAtlasViewerUrlSlideId)
  const { activeViewerId: viewerId } = useOpenViewers()

  const { authorities, user: currentUser } = useTypedSelector((state) => state.user)
  const currentWorkspace = useTypedSelector((state) => state.workspaces.currentWorkspace)
  const isReportModalAvailable = getReportModalAccess(currentWorkspace)
  const isAtlasSource = useTypedSelector((state) => state.viewers[viewerId].viewer.source) === 'ATLAS'

  const isRoleDoctor = authorities?.includes(IUserRole.ROLE_DOCTOR)
  const rightDisabled = index === reportsLength - 1
  const isSigned = isGemotest || isDZM ? selectedReport?.locked : selectedReport?.signed // статус окончательного подписания

  const reportEditEnabled = canEditReport({
    currentUser,
    isDZM,
    isGemotest,
    isReportModalAvailable,
    isRestricted,
    isRoleDoctor,
    lisMode,
    report: selectedReport,
    signed: isSigned,
  })

  useEffect(() => {
    reportsLength < index + 1 && back()
  }, [reportsLength, index])

  return (
    <Header>
      <TitleElement level={3}>{t('Заключение')}</TitleElement>
      <TextElement>{reportsLength > 1 && `${index + 1}/${reportsLength}`}</TextElement>
      {isAtlas && !isAtlasSource && reportsLength > 1 && (
        <Actions onClick={(e) => e.stopPropagation()}>
          <TooltipElement title={t('Предыдущее')}>
            <ActionBtn
              icon="arrowLeftSmall"
              onClick={back}
              style={{ color: leftDisabled ? 'var(--color-text-3)' : 'var(--color-text-1)' }}
            />
          </TooltipElement>
          <TooltipElement title={t('Следующее')}>
            <ActionBtn
              icon="arrowRightSmall"
              onClick={next}
              style={{ color: rightDisabled ? 'var(--color-text-3)' : 'var(--color-text-1)' }}
            />
          </TooltipElement>
        </Actions>
      )}
      <RightActions onClick={(e) => e.stopPropagation()}>
        {isAtlas && !isRestricted && !isAtlasSource && lisMode === 'none' && (
          <TooltipElement title={t('Скопировать')}>
            <ActionBtn disabled={copyDisable} icon="copySmall" onClick={copyReport} />
          </TooltipElement>
        )}
        {!!isSigned && <IconElement name="signed" size="md" />}
        {reportEditEnabled && (
          <TooltipElement placement="topRight" title={t('Редактировать')}>
            <ActionBtn disabled={isDisabledEditReport} icon="editSmall" onClick={editReport} />
          </TooltipElement>
        )}
      </RightActions>
    </Header>
  )
}

export default ReportsWidgetHeader
