import { useTypedSelector } from 'app/redux/lib/selector'
import { checkIntegration } from 'entities/lis-integration'
import { useCaseBioMaterialsQuery } from 'features/cases/api/query'
import { ONE_MINUTE_IN_MS, SEC_TO_MINUTES } from 'features/cases-management/ui/cases-table/CasesTable'
import { reportsSlice } from 'features/reports'
import { onSaveReportError, useReportEditMutation } from 'features/reports/api/query'
import { canEditReport } from 'features/reports/hooks/common'
import { getReportModalAccess } from 'features/reports/lib/utils'
import { MarkerResult } from 'features/reports/ui/report-creation/ReportCreationContext'
import { Icd10Detailed } from 'features/reports/ui/ReportInfo'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import { useLisLockSignedMedicalReportAfterSec, useLisMode } from 'features/workspace/model/workspacesSlice'
import { selectAtlasViewerUrlSlideId } from 'pages/viewer'
import { useOpenViewers } from 'pages/viewer/lib/common/ViewerPageProvider'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { formatDateForDisplay } from 'shared/lib/date'
import { useLisModeAsDZM, useLisModeAsGemotest } from 'shared/lib/workspaces'
import { TextElement, TitleElement } from 'shared/ui/kit'
import { StyledButtonElement } from 'shared/ui/kit/ui/ButtonElement'
import { ModalHandle } from 'shared/ui/modal'
import styled from 'styled-components/macro'
import { ICaseDTO, ICaseRelation } from 'types/ICase'
import IReport from 'types/IReport'
import IUserRole from 'types/IUserRole'

import { RowDescription } from './ReportTabRightContent'

/** Ширина правой части таба */
const LEFT_CONTENT_WIDTH = 272

/** Opacity для заблокированной кнопки */
const DISABLED_BUTTON_OPACITY = 0.4

type TProps = {
  /** Данные о заключение */
  report?: IReport
  /** Данные о кейсе */
  caseRecord?: ICaseDTO
  /** ID кейса */
  caseId: number
  /** ref от модалки создания заключения */
  reportCreationModalRef: React.MutableRefObject<ModalHandle | null>
}

export const MarkerResultsDetailed = ({ markerResults }: { markerResults: MarkerResult[] }) => {
  const { t } = useTranslation()
  const validMarkerResults = markerResults.filter((mr) => mr.markerResult)

  if (!validMarkerResults.length) return null

  return (
    <div>
      <StyledTitle>{t('Результаты по маркерам')}</StyledTitle>
      {validMarkerResults.map(({ caseSlideReferenceId, markerResult, resultText }) => (
        <MarkerRow key={caseSlideReferenceId}>
          <StyledTitle>{caseSlideReferenceId}</StyledTitle>
          <TextElement>{`${'\u00A0'}${markerResult?.name} : ${resultText}`}</TextElement>
        </MarkerRow>
      ))}
    </div>
  )
}

const ReportTabLeftContent: FC<TProps> = ({ caseId, caseRecord, report, reportCreationModalRef }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const {
    consultedBy,
    createdAt,
    createdBy,
    customInfo,
    icd10,
    icd10DetailedList,
    locked,
    markerResults,
    medicalReportId,
    morphologyCode,
    signed,
    signedAt,
  } = report || {}

  const lisMode = useLisMode()
  const isGemotest = useLisModeAsGemotest()
  const isDZM = useLisModeAsDZM()
  const wsId = Number(useCurrentWorkspaceId())
  const { fieldSet, relation } = caseRecord || {}
  const isRestricted = relation === ICaseRelation.RESTRICTED
  const { authorities, user: currentUser } = useTypedSelector((state) => state.user)
  const currentWorkspace = useTypedSelector((state) => state.workspaces.currentWorkspace)
  const isReportModalAvailable = getReportModalAccess(currentWorkspace)
  const isRoleDoctor = authorities?.includes(IUserRole.ROLE_DOCTOR)
  const isAtlas = !useSelector(selectAtlasViewerUrlSlideId)
  const { activeViewerId: viewerId } = useOpenViewers()
  const { isLoading, mutate: editMutate } = useReportEditMutation()
  const isAtlasSource = useTypedSelector((state) => state.viewers[viewerId].viewer.source) === 'ATLAS'
  // период в секундах, в рамках которого можно отменить подписание
  const signCancelPeriod = useLisLockSignedMedicalReportAfterSec() || 0
  // текущее время
  const currentDate = new Date()
  // количество прошедших минут после подписания
  const passedMinutes = signedAt
    ? Math.ceil((currentDate.getTime() - new Date(signedAt || '').getTime()) / ONE_MINUTE_IN_MS)
    : 0
  const leftMinutes = Number(signCancelPeriod) / SEC_TO_MINUTES - passedMinutes
  const [signCancelMinutes, setSignCancelMinutes] = useState<number>(leftMinutes > 0 ? leftMinutes : 0)
  const reportEditEnabled = canEditReport({
    currentUser,
    isDZM,
    isGemotest,
    isReportModalAvailable,
    isRestricted,
    isRoleDoctor,
    lisMode,
    report,
    signed,
  })
  const { data: caseBiomaterial } = useCaseBioMaterialsQuery({
    caseId: caseId,
    workspaceId: wsId,
  })
  const isReportEditDisable = isDZM && !caseBiomaterial?.length
  const isDetailedList = checkIntegration('report', 'icd10DetailedList', lisMode) && icd10DetailedList

  // запускаем таймер для обновления оставшегося времени отмены подписания в ЛИС
  useEffect(() => {
    const timerId = !locked
      ? setInterval(() => {
          signCancelMinutes && setSignCancelMinutes(signCancelMinutes - 1)
        }, ONE_MINUTE_IN_MS)
      : null

    return () => {
      if (timerId) {
        clearTimeout(timerId)
      }
    }
  }, [locked, signCancelMinutes])

  // обновляем время отмены подписания, если поменлся его признак
  useEffect(() => {
    if (signed && signedAt && leftMinutes > 0 && (isGemotest || isDZM)) {
      setSignCancelMinutes(leftMinutes)
    }
  }, [signed, signedAt, leftMinutes, isGemotest, isDZM])

  const editReport = () => {
    reportCreationModalRef.current?.open()
    dispatch(reportsSlice.actions.setInitialReport(report))
  }

  const copyReport = () => {
    reportCreationModalRef.current?.open()
    dispatch(
      reportsSlice.actions.setInitialReport({
        ...report,
        medicalReportId: undefined,
      }),
    )
  }

  /** Запрос на отмену подписания */
  const onCancelSign = async () => {
    caseId &&
      editMutate(
        {
          caseId,
          // принудительно отменяем подписание
          report: {
            ...(report || {}),
            lisVersion: (report?.lisVersion ?? 1) + 1,
            signed: false,
          },
          reportId: Number(medicalReportId),
        },
        { onError: (e) => onSaveReportError(e, caseId) },
      )
  }

  return (
    <div>
      <ContentWrapper
        style={{
          padding: '16px',
          width: `${LEFT_CONTENT_WIDTH}px`,
        }}
      >
        {/* Дата заключения */}
        {checkIntegration('report', 'createdBy-createdAt', lisMode) && createdBy && (
          <RowDescription
            description={createdAt ? formatDateForDisplay(createdAt) : '-'}
            title={t('Дата заключения')}
          />
        )}
        {/* МКБ-10 */}
        {icd10 && !isDetailedList && (
          <RowDescription title={t('МКБ-10')} description={`${icd10.shortName} ${icd10.name}`} />
        )}
        {/* Морфологический код (МКБ-О) */}
        {morphologyCode && !isDetailedList && (
          <RowDescription
            title={t('Морфологический код (МКБ-О)')}
            description={`${morphologyCode.shortName} ${morphologyCode.name}`}
          />
        )}
        {/* Категория сложности */}
        {checkIntegration('report', 'complexity', lisMode) && customInfo?.complexity && (
          <RowDescription description={String(customInfo?.complexity)} title={t('Категория сложности')} />
        )}
        {/* Результаты по маркерам */}
        {markerResults?.length && isDZM && <MarkerResultsDetailed markerResults={markerResults} />}
        {/* МКБ-10 */}
        {isDetailedList &&
          icd10DetailedList.map((it, idx) => <Icd10Detailed key={idx} {...it} showTnn={fieldSet === 'DEFAULT'} />)}
        {/* Врач-патологоанатом */}
        {checkIntegration('report', 'createdBy-createdAt', lisMode) && createdBy?.userId && (
          <RowDescription title={t('Врач-патологоанатом')} description={createdBy?.fullname} />
        )}
        {/* Консультант */}
        {checkIntegration('report', 'consultedBy', lisMode) && consultedBy?.userId && (
          <RowDescription title={t('Консультант')} description={consultedBy?.fullname} />
        )}
        {(reportEditEnabled || (isAtlas && !isAtlasSource && lisMode === 'none')) && <StyledHorizontalDivider />}

        {reportEditEnabled && (
          <StyledButtonElement
            disabled={isReportEditDisable}
            onClick={editReport}
            style={isReportEditDisable ? { opacity: DISABLED_BUTTON_OPACITY } : undefined}
          >
            {t('Редактировать')}
          </StyledButtonElement>
        )}

        {isAtlas && !isRestricted && isRoleDoctor && !isAtlasSource && lisMode === 'none' && (
          <StyledButtonElement onClick={copyReport}>{t('Дублировать')}</StyledButtonElement>
        )}

        {(isGemotest || isDZM) && !!signed && !locked && !!signCancelMinutes && !isRestricted && (
          <div>
            <StyledButtonElement
              style={{ width: `100%` }}
              disabled={isRestricted}
              onClick={onCancelSign}
              loading={isLoading}
            >
              {t('Отменить подписание')}
            </StyledButtonElement>
            <div style={{ marginTop: '8px', textAlign: 'center' }}>
              <TextElement>{`${t('Отмена подписания будет еще доступна в течение')} ${signCancelMinutes} ${t('минут')}${
                signCancelMinutes === 1 ? t('ы') : ''
              }`}</TextElement>
            </div>
          </div>
        )}
      </ContentWrapper>
    </div>
  )
}

export default ReportTabLeftContent

const ContentWrapper = styled.div`
  display: grid;
  row-gap: 8px;
  width: 100%;
  padding: 16px;
  gap: 10px;
`

const StyledHorizontalDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--color-border-1);
`

const MarkerRow = styled.div`
  display: flex;
`

const StyledTitle = styled(TitleElement)`
  &.ant-typography {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: var(--color-text-3);
  }
`
