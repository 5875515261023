import { Form } from 'antd'
import MarkerResultSelectContainer from 'entities/marker-result/ui/MarkerResultSelectContainer'
import { MarkerResult, useReportCreationContext } from 'features/reports/ui/report-creation/ReportCreationContext'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import Flex from 'shared/ui/kit/ui/Flex'
import styled from 'styled-components/macro'
import { DictionaryValidator, StringValidator } from 'types/IValidators'

interface Props {
  /** Функция-валидатор для проверки пустых значений в форме или данных **/
  checkSelectEmpty: DictionaryValidator
  /** Функция-валидатор для строк **/
  stringValidator: StringValidator
}

export const MarkerResultContainer: FC<Props> = ({ checkSelectEmpty, stringValidator }) => {
  const { t } = useTranslation()
  const { form } = useReportCreationContext()

  return (
    <StyledFlex direction={'column'} width={'100%'} gap={8}>
      <Flex width={`100%`} justify={'space-between'} align={'center'}>
        <StyledHeader>{t('Стекло')}</StyledHeader>
        <StyledHeader>{t('Показатель')}</StyledHeader>
        <StyledHeader>{t('Результат') + '*'}</StyledHeader>
      </Flex>
      <Form.List name="markerResults">
        {(fields) => (
          <Flex vertical gap={8} width="100%">
            {fields.map(({ key, name }, index) => {
              //TODO избавиться от getFieldValue(). Использовать только антовские Form name
              const markerResultsList = form.getFieldValue('markerResults') as MarkerResult[]
              const item = markerResultsList && markerResultsList[index]
              if (!item) return null
              const { shortName, slideExternalId, stainId } = item
              return (
                <Flex width={`100%`} justify={'space-between'} align={'center'} key={key}>
                  <StyledTextElement>{slideExternalId}</StyledTextElement>
                  <StyledTextElement>{shortName || '—'}</StyledTextElement>
                  <MarkerResultSelectContainer
                    fieldNumber={name}
                    stainId={stainId}
                    stringValidator={stringValidator}
                    checkSelectEmpty={checkSelectEmpty}
                  />
                </Flex>
              )
            })}
          </Flex>
        )}
      </Form.List>
    </StyledFlex>
  )
}

const StyledFlex = styled(Flex)`
  border: 1px solid var(--color-border-1);
  border-radius: 10px;
  padding: 8px;
`

const StyledTextElement = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 24px;
  flex: 1;
`

const StyledHeader = styled.div`
  padding-bottom: 0;
  display: flex;
  height: 16px;
  margin-bottom: 4px;
  color: var(--color-text-3);
  flex: 1;
`
