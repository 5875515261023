import { Form, Input } from 'antd'
import { useCaseReferencesQuery } from 'features/cases/api/query'
import { useCaseManagementDoctorsQuery } from 'features/cases-management/api/query'
import { useSegmentedHandlers } from 'features/reports/hooks/useSegmentedHandlers'
import { useFormValidators } from 'features/reports/hooks/useValidations'
import Icd10BlockFormList from 'features/reports/ui/icd-10/Icd10BlockList'
import Icd10SingleBlock from 'features/reports/ui/icd-10/Icd10SingleBlock'
import { MarkerResultContainer } from 'features/reports/ui/marker-result/MarkerResultContainer'
import { MarkerResult, useReportCreationContext } from 'features/reports/ui/report-creation/ReportCreationContext'
import { MedicalReportFormItem } from 'features/reports/ui/styled'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import React, { FC, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { QUERY_TYPE } from 'shared/api'
import { isIHCCase, useLisModeAsDZM, useLisModeAsGemotest } from 'shared/lib/workspaces'
import { ButtonElement, SelectElement } from 'shared/ui/kit'
import Flex from 'shared/ui/kit/ui/Flex'
import styled from 'styled-components/macro'
import ICase from 'types/ICase'

import BlockComplexity from './blocks/BlockComplexity'
import BlockServices from './blocks/BlockServices'

const StyledFormWrapper = styled(Flex)`
  padding: 16px 16px 0;
  height: 100%;
  border-top: 1px solid var(--color-border-1);
  border-bottom: 1px solid var(--color-border-1);
  overflow-y: auto;
`

const GOV_FIN_TYPE = ['Бюджет', 'ОМС', 'Гос. задание']

interface Props {
  /** Флаг, указывающий, что заключение в данный момент подписывается. */
  isSigning: boolean
  /** ID кейса */
  caseId: number
}

const ReportCreationFormLeftBodyContainer: FC<Props> = ({ caseId, isSigning }) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const isGemotest = useLisModeAsGemotest()
  const isDZM = useLisModeAsDZM()
  const currentWorkspaceId = useCurrentWorkspaceId()
  const { data: caseReferencesSlides } = useCaseReferencesQuery({
    caseId,
    workspaceId: currentWorkspaceId,
  })
  const {
    billingTypeReportError,
    form,
    initialReport,
    onValuesChange,
    saveReportError,
    selectedComplexity,
    selectedServices,
    setIsIHC,
    setSelectComplexity,
    setServicesModalVisible,
  } = useReportCreationContext()

  const { additionalResearchRequired, customInfo } = initialReport
  const gemotestReport = customInfo || {}
  const { complexity } = gemotestReport
  const caseRecord = queryClient.getQueryData<ICase>([QUERY_TYPE.CASE, caseId])
  const { billingType } = caseRecord || {}
  const isIHC = isIHCCase(caseRecord)
  const hasValidBillingType = !GOV_FIN_TYPE.includes(billingType?.name || '')

  const handleServicesModal = () => setServicesModalVisible(true)
  const wsId = Number(useCurrentWorkspaceId())
  const { data } = useCaseManagementDoctorsQuery(Number(wsId))
  const doctors = data?.content || []
  const errorMessage = saveReportError || billingTypeReportError

  /** Список докторов для дропдауна */
  const selectOptionsDoctorsList = useMemo(
    () => doctors.map((item) => ({ label: item.fullname, value: item.userId })),
    [doctors],
  )

  const { onSegmentedChange, onSegmentedClick } = useSegmentedHandlers(form, setSelectComplexity)
  const {
    validateMessages,
    validators: { selectValidator, stringValidator },
  } = useFormValidators(isSigning)

  useEffect(() => {
    setIsIHC(isIHC)
  }, [isIHC])

  useEffect(() => {
    const userId = form.getFieldValue('consultedBy')?.userId
    form.setFieldValue('consultedBy', userId)
  }, [form])

  useEffect(() => {
    if (!caseReferencesSlides?.length) return
    const initialMarkerResultsList: MarkerResult[] = form.getFieldValue('markerResults') || []
    // Объединяем данные ReferencesSlides с данными по их маркерами
    const normalizedReferencesSlides = caseReferencesSlides?.map(({ caseSlideReferenceId, slideExternalId, stain }) => {
      const localMarkerResult = initialMarkerResultsList.find(
        ({ caseSlideReferenceId: markerCaseSlideReferenceId }) => markerCaseSlideReferenceId === caseSlideReferenceId,
      )

      return {
        ...(localMarkerResult || {}),
        caseSlideReferenceId,
        // resultText не может быть undefined
        resultText: localMarkerResult?.resultText ?? '.',
        shortName: stain?.shortName,
        slideExternalId,
        // Невалидная окраска stainId помечается null
        stainId: stain?.id ?? null,
      }
    })
    form.setFieldValue('markerResults', normalizedReferencesSlides)
  }, [caseReferencesSlides])

  return (
    <StyledFormWrapper vertical justify="space-between">
      <Form
        form={form}
        layout="vertical"
        initialValues={{ ...initialReport, complexity }}
        validateMessages={validateMessages}
        onValuesChange={onValuesChange}
      >
        <Flex vertical align="start" justify="start" gap={24} height="100%">
          <BlockComplexity
            additionalResearchRequired={additionalResearchRequired}
            isIHC={isIHC}
            isSigning={isSigning}
            complexity={complexity || undefined}
          />
          {/* МКБ-10  */}
          {isDZM ? (
            <Icd10BlockFormList
              onSegmentedClick={onSegmentedClick}
              onSegmentedChange={onSegmentedChange}
              checkSelectEmpty={selectValidator}
              isTNMFieldSet={caseRecord?.fieldSet === 'TNM'}
            />
          ) : (
            <Icd10SingleBlock checkSelectEmpty={selectValidator} />
          )}
          {/* Результаты по маркерам */}
          {!isGemotest && !!caseReferencesSlides?.length && (
            <MarkerResultContainer checkSelectEmpty={selectValidator} stringValidator={stringValidator} />
          )}
          {!isGemotest && !!selectedServices.length && <BlockServices services={selectedServices} />}
          {!isGemotest && (selectedComplexity || complexity) && hasValidBillingType && (
            <ButtonElement onClick={handleServicesModal}>{t('Добавить услугу')}</ButtonElement>
          )}
          {/* Врач-консультант */}
          {isDZM && (
            <MedicalReportFormItem
              style={{
                width: '100%',
              }}
              label={t('Врач-консультант')}
              name="consultedBy"
            >
              <SelectElement
                style={{
                  width: '100%',
                }}
                placeholder={t('Выберите из списка')}
                options={selectOptionsDoctorsList}
              />
            </MedicalReportFormItem>
          )}
        </Flex>
        <Form.Item name="signed" noStyle>
          <Input type="hidden" />
        </Form.Item>
      </Form>
      {!!errorMessage && (
        <div style={{ marginTop: 16 }}>
          <ErrorText
            style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
            title={errorMessage}
          >
            {errorMessage}
          </ErrorText>
        </div>
      )}
    </StyledFormWrapper>
  )
}

export default ReportCreationFormLeftBodyContainer

const ErrorText = styled.div`
  background-color: var(--color-red);
  border-radius: 5px;
  padding: 8px;
  height: 34px;
`
