import { InputNumberProps } from 'antd/es/input-number'
import { modalBackground, modalShadow } from 'app/styled/GlobalStyles'
import { useReportCreationContext } from 'features/reports/ui/report-creation/ReportCreationContext'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IconElement, InputNumberElement, TextElement } from 'shared/ui/kit'
import styled from 'styled-components'
import { BillableServicesType } from 'types/IDictionary'

const BlockServices = ({ services }: { services: BillableServicesType[] }) => {
  const { t } = useTranslation()
  const { setSelectedServices } = useReportCreationContext()

  const handleQuantity = (value: InputNumberProps['value'], serviceId: number) => {
    if (!value) return

    setSelectedServices((prev) =>
      prev.map((service) =>
        service.billableService.id === serviceId
          ? {
              ...service,
              quantity: Number(value),
              total: service.price * Number(value),
            }
          : service,
      ),
    )
  }

  const handleRemoveService = (medicalReportBillableServiceId: number) => {
    setSelectedServices((prev) =>
      prev.filter((service) => service.billableService.id !== medicalReportBillableServiceId),
    )
  }

  return (
    <BlockContainer>
      <HeaderRow>
        <StyledTextElement color="var(--color-text-3)" style={{ width: 234 }}>
          {t('Услуга')}
        </StyledTextElement>
        <StyledTextElement color="var(--color-text-3)" style={{ width: 40 }}>
          {t('Кол-во')}
        </StyledTextElement>
        <PriceColumn color="var(--color-text-3)" style={{ width: 70 }}>
          {t('Стоимость')}
        </PriceColumn>
      </HeaderRow>
      {services.map(({ billableService, price, quantity }) => (
        <ServiceRow key={billableService.id}>
          <ServiceNameContainer>
            <StyledTextName style={{ width: 231 }}>
              {billableService.shortName}
              {billableService.name}
            </StyledTextName>
            <IconWrapper
              onClick={() => billableService.id && handleRemoveService(billableService.id)}
              className="icon-wrapper"
            >
              <IconElement name="delete" size="md" />
            </IconWrapper>
          </ServiceNameContainer>
          <StyledInput
            min={1}
            value={quantity || 1}
            onChange={(e) => handleQuantity(e, billableService.id)}
            defaultValue={quantity || 1}
          />
          <PriceColumn style={{ width: 70 }}>{price} ₽</PriceColumn>
        </ServiceRow>
      ))}
    </BlockContainer>
  )
}

export default BlockServices

const BlockContainer = styled.div`
  border: 1px solid var(--color-border-1);
  border-radius: 10px;
  display: grid;
  width: 376px;
  padding: 8px 0px;
  gap: 4px;
  overflow: hidden;
`

const HeaderRow = styled.div`
  display: flex;
  gap: 8px;
  padding: 0px 8px;
  align-items: center;
`

const ServiceRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 8px;
  &:hover {
    background: var(--color-bg-3);
    .icon-wrapper {
      opacity: 1;
      visibility: visible;
    }
  }
`

const StyledTextElement = styled(TextElement)<{ color?: string }>`
  cursor: default;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 24px;
  display: flex;
  align-items: center;
  color: ${({ color }) => color} !important;
`

const StyledTextName = styled.div`
  cursor: default;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  width: 100%;
`

const StyledInput = styled(InputNumberElement)`
  width: 39px;
`

const PriceColumn = styled(TextElement)<{ color?: string }>`
  color: ${({ color }) => color} !important;
  text-align: end;
`

const IconWrapper = styled.div`
  ${() => modalBackground};
  ${() => modalShadow};
  border-radius: 5px;
  padding: 4px;
  position: absolute;
  right: 8px;
  display: flex;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease, visibility 0.2s ease;
  .icon-wrapper {
    display: flex;
    align-items: center;
  }
  & > svg {
    color: #c4c4c4;
    cursor: pointer;
  }
`

const ServiceNameContainer = styled.div`
  align-items: center;
  display: flex;
  overflow: hidden;
  position: relative;
  width: 231px;
`
